/* Pixels - These control the template breakpoints only - they will not affect the bootstrap grid */
/* Breakpoints */
/* GENERAL STYLES
---------------------------------------------*/
html,
body,
form {
  height: 100%;
  width: 100%;
}
body {
  margin: 0;
  padding: 0;
  font: 16px/1.5em 'Roboto', Helvetica, Arial, sans-serif;
  background: #D1D2D0;
  color: #353535;
}
a {
  color: #a5034c;
  text-decoration: underline;
  outline: none;
  transition: color 250ms ease, background 500ms ease;
}
a:hover,
a:focus {
  color: #a5034c;
  text-decoration: none;
}
a img {
  border: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
.PageTitleHeader,
.PageSubTitleHeader {
  font-weight: bold;
  margin: 1.75em 0 0.5em;
  padding: 0;
}
h1:first-child,
h2:first-child,
h3:first-child,
h4:first-child,
h5:first-child,
h6:first-child,
.PageTitleHeader:first-child,
.PageSubTitleHeader:first-child {
  margin-top: 0;
}
h1,
.PageTitleHeader {
  font-size: 2.625em;
  line-height: 1.25em;
  text-transform: uppercase;
  color: #353535;
}
@media only screen and (max-width:  699px ) {
  h1,
  .PageTitleHeader {
    font-size: 2em;
    line-height: 1.1em;
  }
}
h2,
.PageSubTitleHeader {
  font-size: 1.875em;
  line-height: 1.25em;
  text-transform: uppercase;
  color: #353535;
}
@media only screen and (max-width:  699px ) {
  h2,
  .PageSubTitleHeader {
    font-size: 1.5em;
    line-height: 1.3em;
  }
}
h3 {
  font-size: 1.875em;
  line-height: 1.25em;
  color: #002857;
}
@media only screen and (max-width:  699px ) {
  h3 {
    font-size: 1.5em;
    line-height: 1.3em;
  }
}
h4 {
  font-size: 1.5em;
  line-height: 1.25em;
  color: #a5034c;
  text-transform: uppercase;
}
h5 {
  font-size: 1.5em;
  line-height: 1.25em;
  color: #002857;
}
@media only screen and (max-width:  699px ) {
  h5 {
    font-size: 1.25em;
    line-height: 1.3em;
  }
}
h6 {
  font-size: 1.25em;
  line-height: 1.25em;
  text-transform: uppercase;
}
@media only screen and (max-width:  699px ) {
  h6 {
    font-size: 1.25em;
    line-height: 1.3em;
  }
}
p {
  margin: 0.5em 0 1.5em;
  padding: 0;
}
p:first-child {
  margin-top: 0;
}
#PageTitle,
.PageTitleHeader {
  display: none;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center {
  text-align: center;
}
.textalignright {
  text-align: right;
}
.textalignleft {
  text-align: left;
}
hr {
  height: 1px;
  line-height: 1px;
  margin: 4em 0;
  clear: both;
  border: none;
  background: #C9CAC8;
  color: #C9CAC8;
}
img.left {
  float: left;
  margin: 0 30px 15px 0;
}
img.right {
  float: right;
  margin: 0 0 15px 30px;
}
@media only screen and (max-width:  699px ) {
  img.left,
  img.right {
    float: none;
    display: block;
    margin: 15px auto;
  }
}
img.outline {
  border: 4px solid #353535;
}
.clearing {
  clear: both;
  height: 0;
  margin: 0;
  padding: 0;
  line-height: 0;
  margin-top: -7px;
}
.clearBoth {
  clear: both;
}
.clearRight {
  clear: right;
}
.clearLeft {
  clear: left;
}
.invisibleButAccessible {
  display: none;
}
.idbmsBreadcrumbSeparator {
  color: green !important;
}
.expand img {
  background-color: green !important;
}
.expand {
  background-color: green !important;
  display: inline-block;
  width: 18px;
}
.Error,
.error {
  width: auto!important;
}
img[src="/images/icons/v2/common/brick_edit.gif"],
img[src="/images/icons/v2/common/delete.gif"],
img[src="/images/icons/v2/common/arrow_out.gif"],
img[src="/images/e.gif"],
img[src="/images/spacer.gif"],
img[src="/images/icons/v2/bomb.png"] {
  border: 0!important;
  padding: 0!important;
  margin: 0!important;
  float: none!important;
  width: 16px!important;
  height: 16px !important;
}
#inner-wrap img {
  max-width: 100%;
  height: auto;
}
#inner-wrap img[src="/images/spacer.gif"] {
  display: none;
}
#inner-wrap img * {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.EventsHeader td:nth-child(2) {
  width: 18px !important;
  height: 18px !important;
}
.EventsHeader span.expand img {
  width: 18px !important;
  height: 18px !important;
}
::selection {
  background: blue;
  color: #fff;
}
/* Safari 3.1+, Opera 9.5+, Chrome 2+ */
::-moz-selection {
  background: blue;
  color: #fff;
}
/* Mozilla Firefox */
/* Flexbox Equal heights */
.support-flexbox .equal-height {
  display: flex;
  flex-direction: row;
}
.support-flexbox .equal-height-item {
  display: inherit;
  flex-direction: column;
}
.support-flexbox .equal-height-item-inner {
  flex: 1 0 auto;
}
/* Stack items on mobile */
@media only screen and (max-width:  699px ) {
  .support-flexbox .equal-height {
    flex-direction: column;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .container {
    width: 100%;
  }
}
.button {
  background-color: #a5034c;
  color: #fff;
  display: inline-block;
  font-weight: bold;
  margin: 10px 10px 10px 0;
  border: none;
  padding: 15px 35px;
  text-decoration: none;
  text-transform: uppercase;
  transition: all ease 200ms;
}
.button:focus {
  outline: 2px solid #353535;
  color: #fff;
  background-color: #730235;
}
.button:hover {
  background-color: #730235;
  color: #fff;
  text-decoration: none;
}
main .snippetrow {
  margin: 0 -15px;
  width: auto;
}
main .snippetrow > * {
  padding: 0 15px;
}
/* UTILITY BAR ELEMENTS
---------------------------------------------*/
.utilityBar {
  float: left;
  width: 100%;
  background: #4D4F53;
  padding: 0;
  min-height: 8px;
  position: relative;
  z-index: 115;
}
@media only screen and (max-width:  699px ) {
  .utilityBar {
    min-height: 5px;
  }
}
@media only screen and (max-width:  1379px ) {
  .utilityBar .utilInner {
    padding-right: 100px;
  }
}
.memberTools {
  float: right;
  margin-right: 0px;
  font-size: 0.8125em;
  line-height: 36px;
}
.memberTools ul {
  list-style: none;
  margin: 0;
  padding: 0;
  float: right;
}
.memberTools ul li {
  float: left;
  padding: 0 0 0 0px;
}
.memberTools ul li a {
  display: block;
  color: #fff;
  border-left: 1px solid #686C73;
  padding: 0 20px;
  text-transform: uppercase;
  text-decoration: none;
}
.memberTools ul li a:hover,
.memberTools ul li a:focus {
  background-color: #686C73;
}
.memberTools ul li a:focus {
  outline: 2px solid #353535;
}
.memberTools ul li.siteSearchToggle a {
  height: 36px;
}
.memberTools ul li.siteSearchToggle a .fa-search,
.memberTools ul li.siteSearchToggle a .fa-times {
  height: 36px;
  line-height: 36px;
}
.memberTools ul li.siteSearchToggle a .fa-times {
  display: none;
}
.memberTools ul li.siteSearchToggle a.showSearch {
  background: #686C73;
}
.memberTools ul li.siteSearchToggle a.showSearch .fa-search {
  display: none;
}
.memberTools ul li.siteSearchToggle a.showSearch .fa-times {
  display: block;
}
.searchWrap {
  position: absolute;
  top: 100%;
  right: 15px;
  width: 400px;
  background: #686C73;
  z-index: 100;
  padding: 0px;
  transition: all ease 250ms;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0.5px;
  display: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  color: #fff;
}
@media only screen and (min-width: 1024px) and (max-width:  1379px ) {
  .searchWrap {
    right: 100px;
  }
}
.searchWrap .search {
  position: relative;
  padding-right: 50px;
  border-top: 1px solid #fff;
}
.searchWrap .search label,
.searchWrap .search .SearchModuleSpacer {
  display: none;
}
.searchWrap .search input {
  width: 100%;
  background: transparent;
  border: none;
  color: #fff;
  padding: 0 10px;
  height: 50px;
}
.searchWrap .search input:focus {
  outline: 2px solid #353535;
}
.searchWrap .search [id$="_imgbtnSearch"] {
  position: absolute;
  right: 0;
  top: 0;
  height: 50px;
  width: 50px;
  padding: 12px;
  display: block;
}
.searchWrap .search [id$="_imgbtnSearch"]:focus,
.searchWrap .search [id$="_imgbtnSearch"]:hover {
  background: #353535;
}
/* HEAD ELEMENTS
---------------------------------------------*/
.structHead {
  float: left;
  width: 100%;
  background: #F2F2F2;
  padding: 0;
}
.structHead > .container {
  position: relative;
}
.structHead > .container > .row {
  display: flex;
  align-items: center;
  position: relative;
}
@media only screen and (max-width:  699px ) {
  .structHead > .container > .row {
    display: block;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .structHead {
    padding-top: 10px;
    padding-bottom: 10px;
  }
}
.headLogo {
  font-weight: 300;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 0;
}
@media only screen and (max-width:  699px ) {
  .headLogo {
    padding: 15px;
  }
}
.headLogo a {
  text-decoration: none;
  display: flex;
  align-items: center;
}
@media only screen and (max-width:  699px ) {
  .headLogo a {
    justify-content: space-between;
  }
}
.headLogo img {
  width: 215px;
  transition: ease 250ms all;
  margin-right: 25px;
}
@media only screen and (min-width: 1380px) {
  .headLogo img {
    margin-left: -90px;
  }
}
@media only screen and (max-width:  699px ) {
  .headLogo img {
    width: 125px;
    margin: 0;
  }
}
.headLogo .logoTxt {
  color: #353535;
  text-decoration: none;
  font-size: 1.65em;
  line-height: 1em;
  font-weight: 300;
}
@media only screen and (min-width: 1380px) {
  .headLogo .logoTxt {
    font-size: 1.75em;
  }
}
@media only screen and (max-width:  699px ) {
  .headLogo .logoTxt {
    font-size: 1.125em;
    line-height: 1em;
    padding: 0;
  }
}
.giveBtnWrap {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 150;
}
@media only screen and (max-width:  699px ) {
  .giveBtnWrap {
    position: static;
    width: 50%;
    float: left;
  }
}
.giveBtnWrap > .container {
  position: relative;
}
.giveBtnWrap .giveBtn {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 80px;
  height: 140px;
  background-color: #a5034c;
  position: absolute;
  right: 0px;
  top: 0;
  transition: all ease 250ms;
  font-size: 1.5em;
  line-height: 1.1em;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  text-align: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  margin-top: -5px;
  padding-top: 5px;
}
.giveBtnWrap .giveBtn:hover {
  margin-top: 0;
}
.giveBtnWrap .giveBtn:focus {
  outline: 2px solid #353535;
}
@media only screen and (min-width: 1380px) {
  .giveBtnWrap .giveBtn {
    right: -100px;
  }
}
@media only screen and (max-width:  699px ) {
  .giveBtnWrap .giveBtn {
    position: static;
    width: 100%;
    height: 45px;
    flex-direction: unset;
    margin: 0;
    box-shadow: none;
    padding: 0 15px;
    font-size: 1.125em;
    line-height: 1em;
    justify-content: flex-start;
  }
}
.giveBtnWrap .giveBtn .icon {
  width: 53px;
  height: 48px;
}
@media only screen and (max-width:  699px ) {
  .giveBtnWrap .giveBtn .icon {
    width: 27px;
    height: 24px;
    margin-right: 10px;
  }
}
.loggedin .giveBtnWrap {
  top: 65px;
}
.menuToggleBtnWrap {
  padding-right: 100px;
  text-align: right;
}
@media only screen and (max-width:  699px ) {
  .menuToggleBtnWrap {
    float: right;
    width: 50%;
    background: #353535;
    color: #fff;
    padding-right: 15px;
  }
}
.menuToggleBtnWrap button {
  height: 50px;
  border: none;
  background: none;
  float: right;
  font-size: 1.5em;
  padding: 0 15px;
}
.menuToggleBtnWrap button:focus {
  outline: 2px solid #4D4F53;
  background-color: #D1D2D0;
}
.menuToggleBtnWrap button .menuTitle {
  display: inline-block;
  padding-right: 5px;
}
@media only screen and (max-width:  699px ) {
  .menuToggleBtnWrap button {
    height: 45px;
    font-size: 1.125em;
    line-height: 1em;
    font-weight: bold;
    text-transform: uppercase;
    display: block;
  }
  .menuToggleBtnWrap button:focus {
    background: #4D4F53;
    outline: 2px solid #D1D2D0;
  }
}
#nav {
  display: none;
}
/* MAIN TOP HORIZONTAL NAV
---------------------------------------------*/
.desktopNav {
  position: relative;
  z-index: 99;
}
@media only screen and (max-width:  1379px ) {
  .desktopNav {
    padding-right: 80px;
  }
}
.desktopNav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}
.desktopNav ul li {
  float: left;
  padding: 0;
  line-height: 1.5em;
  position: relative;
}
.desktopNav ul li a {
  display: block;
  text-decoration: none;
}
.desktopNav .desktopNavInner > ul > li {
  position: relative;
  margin-left: 1px;
}
.desktopNav .desktopNavInner > ul > li > a {
  font-weight: bold;
  text-transform: uppercase;
  color: #353535;
  font-size: 1.0625em;
  line-height: 1em;
  border-bottom: 10px solid transparent;
  padding-top: 15px;
  padding-bottom: 5px;
  transition: all ease 250ms;
}
.desktopNav .desktopNavInner > ul > li > a:focus {
  border-color: #ccc;
}
.desktopNav .desktopNavInner > ul > li:hover > a {
  border-color: #686C73;
}
.desktopNav .desktopNavInner > ul > li.selected > a {
  border-color: #002857;
}
.desktopNav li.sfHover {
  position: static;
}
.desktopNav li.sfHover ul {
  left: 0px;
  opacity: 1;
}
.desktopNav li.sfHover ul ul {
  left: -999em;
}
.desktopNav li.sfHover ul ul ul {
  left: -999em;
}
.desktopNav li ul {
  position: absolute;
  left: -999em;
  margin: 0px;
  background: #C9CAC8;
  float: none;
  width: 200px;
  text-align: left;
  opacity: 0;
  transition: opacity 500ms ease;
  top: 100%;
  font-size: 0.875em;
  line-height: normal;
}
.desktopNav li ul li {
  width: 100%;
  border: none;
  margin: 0;
  padding: 0;
  background: none;
  line-height: normal;
  text-transform: none;
  font-size: 1em;
}
.desktopNav li ul li.sfHover {
  position: static;
}
.desktopNav li ul li.sfHover ul {
  left: 0;
  opacity: 1;
}
.desktopNav li ul li:hover a {
  background: #4D4F53;
  color: #fff;
}
.desktopNav li ul li a {
  display: block;
  padding: 10px 15px;
  border-bottom: 1px solid #999;
  color: #353535;
  line-height: normal;
}
.desktopNav li ul li a:focus {
  background: #4D4F53;
  color: #fff;
}
.desktopNav li ul li ul {
  margin: -23px 0 0 150px;
  background: green;
  color: #fff;
  width: 170px;
}
.desktopNav li ul li ul li {
  width: 170px;
}
.desktopNav li ul li ul li.sfHover {
  position: static;
}
.desktopNav li ul li ul li.sfHover ul {
  left: auto;
  opacity: 1;
}
.desktopNav li ul li ul li a {
  color: #fff;
}
.desktopNav li ul li ul li a:hover {
  color: #D1D2D0;
}
.desktopNav li ul li ul a {
  font-weight: normal;
  text-align: left;
  padding: 0.25em 1em;
  border: none;
}
/* Flexbox styles */
.support-flexbox .desktopNavInner > ul {
  display: flex;
  justify-content: flex-end;
}
.support-flexbox .desktopNavInner > ul > li {
  float: none;
  display: flex;
  flex-direction: column;
}
.support-flexbox .desktopNavInner > ul > li > a {
  height: 90px;
  display: flex;
  width: 100%;
  line-height: 1.25em;
  padding: 15px 15px 5px;
  text-align: center;
  flex: 1 0 auto;
  align-items: center;
  justify-content: center;
}
/* BREADCRUMBS
-----------------------------------------------*/
.structBody .breadCrumbs {
  font-size: 0.8125em;
  line-height: 1.1em;
  text-transform: uppercase;
  margin-top: 45px;
}
.structBody .breadCrumbs ul {
  padding: 0 0 10px;
  margin: 0 0 40px;
  list-style: none;
  border-bottom: 1px solid #8A8D8F;
}
.structBody .breadCrumbs ul li {
  padding: 0 0 0 0;
  margin: 0;
  display: inline-block;
}
.structBody .breadCrumbs ul li:before {
  content: "|";
}
.structBody .breadCrumbs ul li:first-child:before {
  content: "";
}
.structBody .breadCrumbs ul li:first-child a {
  padding-left: 0;
}
.structBody .breadCrumbs ul li.selected a {
  font-weight: bold;
}
.structBody .breadCrumbs ul li a {
  color: #353535;
  text-decoration: none;
  padding: 0 4px 0 8px;
}
/* BODY ELEMENTS
---------------------------------------------*/
.structBody {
  float: left;
  padding: 0em 0 80px;
  width: 100%;
  background: #fff;
}
@media only screen and (max-width:  699px ) {
  .structBody {
    padding-bottom: 30px;
  }
}
.midWrap {
  padding-bottom: 3em;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .midWrap {
    padding-bottom: 1em;
  }
}
.pageName h1 {
  margin: 0 0 30px;
}
.interior .ftImg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
.interior .ftImg .invisible {
  visibility: visible;
  opacity: 0;
}
.interior .mainCol {
  padding-left: 30px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .interior .mainCol {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width:  699px ) {
  .interior .mainCol {
    padding-top: 30px;
    padding-left: 15px;
    padding-right: 15px;
  }
}
/* Directory Search styling */
main .imod-search-form .imod-field-item {
  padding: 0;
  margin: 7px 0;
}
/* First Time Login Styling */
.IMOD8 #cid_46_pnlResults {
  position: relative;
  padding-bottom: 100px;
}
.IMOD8 table.DataGrid {
  width: 100%;
}
.IMOD8 table.DataGrid .DataGridHeader {
  background: #353535;
  padding: 0;
  border: 0;
  margin: 0;
  color: #fff;
}
.IMOD8 table.DataGrid .DataGridHeader th {
  font-weight: bold;
  font-size: 0.85em;
  text-transform: uppercase;
  padding: 15px 20px 10px 0;
  line-height: 1em;
}
.IMOD8 table.DataGrid .DataGridItem td,
.IMOD8 table.DataGrid .DataGridAltItem td {
  padding: 5px 10px 5px 0;
}
.IMOD8 table.DataGrid .DataGridItem td:first-child,
.IMOD8 table.DataGrid .DataGridAltItem td:first-child {
  padding-left: 10px;
}
.IMOD8 table.DataGrid .DataGridAltItem {
  background: #D1D2D0;
}
.IMOD8 table.DataGrid .DataGridFooter {
  position: absolute;
  bottom: 0;
  left: 0;
}
.IMOD8 table.DataGrid .DataGridFooter input {
  width: auto!important;
}
/* NEWS LISTINGS
-----------------------------------------------*/
.newsWrap {
  margin: 2em 0 4em;
  overflow: hidden;
}
@media only screen and (max-width:  699px ) {
  .newsWrap {
    margin: 1em 0 2em;
  }
}
.newsItem {
  position: relative;
  padding: 35px 0;
  border-bottom: 1px solid #C9CAC8;
  float: left;
  width: 100%;
  clear: both;
}
@media only screen and (max-width:  699px ) {
  .newsItem {
    padding: 20px 0;
  }
}
.newsItem:first-of-type {
  padding-top: 0;
}
.newsItem .thumb {
  position: relative;
  width: 120px;
  height: 120px;
  float: left;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  background-size: 100%;
  transition: all ease 250ms;
}
.newsItem .thumb .invisible {
  visibility: visible;
  opacity: 0;
}
.newsItem .text {
  padding-left: 150px;
}
@media only screen and (max-width:  699px ) {
  .newsItem .text {
    padding-left: 140px;
  }
}
.newsItem .title {
  /* these are all now h3 in the patterns */
  font-size: 1.5em;
  line-height: normal;
  padding: 0 0px;
  color: #a5034c;
  margin: 0;
  font-weight: normal;
}
@media only screen and (max-width:  699px ) {
  .newsItem .title {
    font-size: 1.25em;
  }
}
.newsItem .preview {
  padding: 10px 0 0px;
}
@media only screen and (max-width:  699px ) {
  .newsItem .preview {
    display: none;
  }
}
.newsItem a {
  text-decoration: none;
  color: #353535;
  overflow: hidden;
  display: block;
}
.newsItem a:focus {
  outline: 2px solid #D1D2D0;
}
.newsItem a:hover .title,
.newsItem a:focus .title {
  text-decoration: underline;
}
.newsItem a:hover .thumb,
.newsItem a:focus .thumb {
  background-size: 115%;
}
.newsItem .tools a {
  display: inline-block;
}
.slickWrap {
  margin: 0 -15px;
  position: relative;
}
.slickWrap .tools a {
  display: inline-block;
}
.slickWrap a {
  text-decoration: none;
  display: block;
}
.slickWrap a:focus {
  outline: 2px solid #D1D2D0;
}
.slickWrap .thumb {
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transition: ease all 250ms;
  width: 100%;
  height: 0;
  padding-top: 100%;
  display: block;
  margin: 0 auto 20px;
}
@media only screen and (max-width:  699px ) {
  .slickWrap .thumb {
    width: 260px;
    height: 260px;
    padding: 0;
  }
}
.slickWrap .thumb img.invisible {
  visibility: visible;
  opacity: 0;
}
.slickWrap .text .title {
  font-weight: bold;
  text-transform: uppercase;
  color: #a5034c;
  font-size: 1.25em;
  line-height: 1.2em;
  display: block;
  margin: 0;
}
@media only screen and (max-width:  699px ) {
  .slickWrap .text .title {
    font-size: 1.125em;
    line-height: 1.2em;
    text-align: center;
  }
}
.slickWrap .text .preview {
  padding-top: 8px;
  color: #353535;
}
@media only screen and (max-width:  699px ) {
  .slickWrap .text .preview {
    display: none;
  }
}
.slickWrap a:hover .thumb {
  background-size: 110%;
}
.slickWrap a:hover .title {
  text-decoration: underline;
}
.slickWrap .slick-arrow {
  display: block;
  width: 36px;
  height: 36px;
  font-size: 26px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 50px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  border: none;
  position: absolute;
  top: 130px;
  margin-top: -18px;
  z-index: 100;
  opacity: 1;
  transition: ease all 500ms;
  font-size: 20px;
}
.slickWrap .slick-arrow:focus {
  outline: 2px solid #353535;
}
.slickWrap .slick-arrow.slick-disabled {
  opacity: 0.15;
  cursor: not-allowed;
}
.slickWrap .slick-arrow.slick-prev {
  left: 0;
}
@media only screen and (max-width:  699px ) {
  .slickWrap .slick-arrow.slick-prev {
    left: 10px;
  }
}
.slickWrap .slick-arrow.slick-next {
  right: 0;
}
@media only screen and (max-width:  699px ) {
  .slickWrap .slick-arrow.slick-next {
    right: 10px;
  }
}
.slickWrap.slickCircleWrap .thumb {
  border-radius: 50%;
}
.slickWrap.slickCircleWrap .text {
  text-align: center;
}
/* EVENT LISTINGS
-----------------------------------------------*/
.eventsWrap {
  margin: 2em 0 4em;
  overflow: hidden;
}
@media only screen and (max-width:  699px ) {
  .eventsWrap {
    margin: 1em 0 2em;
  }
}
.eventItem {
  position: relative;
  width: 100%;
  padding: 35px 0;
  border-bottom: 1px solid #C9CAC8;
  overflow: hidden;
}
@media only screen and (max-width:  699px ) {
  .eventItem {
    padding: 20px 0;
  }
}
.eventItem:first-of-type {
  padding-top: 0;
}
.eventItem .calIcon {
  display: inline-block;
}
.eventItem .date {
  position: relative;
  width: 85px;
  background: #002857;
  text-align: center;
  float: left;
  color: #fff;
  height: 85px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.eventItem .date .month {
  font-size: 1em;
  line-height: 1em;
  padding: 0 0 2px;
}
.eventItem .date .day {
  font-size: 1.75em;
  line-height: 1em;
}
.eventItem .text {
  padding-left: 110px;
}
@media only screen and (max-width:  699px ) {
  .eventItem .text {
    padding-left: 100px;
  }
}
.eventItem.withThumb .text {
  padding-right: 150px;
}
@media only screen and (max-width:  699px ) {
  .eventItem.withThumb .text {
    padding-right: 0;
  }
}
.eventItem .locationTime {
  color: #4D4F53;
  font-size: 0.875em;
  margin: 5px 0px -10px;
}
.eventItem .locationTime .icon {
  margin-right: 5px;
}
.eventItem .locationTime .location {
  display: inline-block;
  padding-right: 15px;
  line-height: normal;
}
@media only screen and (max-width:  699px ) {
  .eventItem .locationTime .location {
    display: inline-block;
  }
}
.eventItem .locationTime .timeRange {
  display: inline-block;
}
@media only screen and (max-width:  699px ) {
  .eventItem .locationTime .timeRange {
    display: inline-block;
    padding-left: 0px;
  }
}
.eventItem .title {
  /* these are now all h3 in the pattern */
  font-size: 1.5em;
  line-height: normal;
  margin: 0 0 5px;
  color: #002857;
  font-weight: normal;
}
@media only screen and (max-width:  699px ) {
  .eventItem .title {
    font-size: 1.25em;
  }
}
.eventItem .title a {
  color: #002857;
  text-decoration: none;
}
.eventItem .title a:hover,
.eventItem .title a:focus {
  text-decoration: underline;
}
.eventItem .preview {
  margin-top: 15px;
}
@media only screen and (max-width:  699px ) {
  .eventItem .preview {
    display: none;
  }
}
.eventItem .thumb {
  float: right;
  width: 120px;
  height: 120px;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  transition: all ease 250ms;
}
@media only screen and (max-width:  699px ) {
  .eventItem .thumb {
    display: none;
  }
}
.eventItem .thumb .invisible {
  visibility: visible;
  opacity: 0;
}
.eventItem:hover .thumb {
  background-size: 115%;
}
/* HERO ROTATOR
---------------------------------------------*/
.heroBanner {
  position: relative;
  background-color: #353535;
  z-index: 0;
}
.heroBanner .scrollArw {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  text-align: center;
  z-index: 200;
  color: #fff;
  font-size: 144px;
  opacity: 0.4;
}
@media only screen and (min-width: 1024px) and (max-width:  1379px ) {
  .heroBanner .scrollArw {
    font-size: 100px;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .heroBanner .scrollArw {
    font-size: 80px;
  }
}
.heroBanner .playPause {
  position: absolute;
  right: 50px;
  bottom: 0;
  z-index: 500;
  padding-right: 50px;
}
.heroBanner .playPause button {
  background: none;
  border: none;
  color: #fff;
  padding: 10px 5px;
  font-size: 24px;
  line-height: 1em;
}
.heroBanner .playPause button.disabled {
  opacity: 0.15;
  cursor: not-allowed;
}
.heroBanner .playPause button:focus {
  outline: 2px solid #353535;
}
.heroBanner button.slick-arrow {
  position: absolute;
  top: 0;
  height: 100%;
  width: 50px;
  z-index: 220;
  background: none;
  border: none;
  color: #fff;
  font-size: 50px;
  width: 80px;
}
.heroBanner button.slick-arrow:focus {
  background: rgba(0, 40, 87, 0.5);
}
@media only screen and (max-width:  699px ) {
  .heroBanner button.slick-arrow {
    font-size: 25px;
    width: 40px;
  }
}
.heroBanner button.slick-arrow.slick-prev {
  left: 0;
}
.heroBanner button.slick-arrow.slick-next {
  right: 0;
}
.heroBanner .slickItem-hero .sldimg {
  position: relative;
  z-index: 5;
}
.heroBanner .slickItem-hero .sldimg .colorOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  background-color: rgba(77, 79, 83, 0.67);
}
.heroBanner .slickItem-hero .sldimg .imgWrap {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  display: block;
  position: relative;
  z-index: 5;
}
.heroBanner .slickItem-hero .sldimg .imgWrap img {
  opacity: 0;
  height: 720px!important;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .heroBanner .slickItem-hero .sldimg .imgWrap img {
    height: 400px!important;
  }
}
@media only screen and (max-width:  699px ) {
  .heroBanner .slickItem-hero .sldimg .imgWrap img {
    height: auto!important;
  }
}
.heroBanner .slickItem-hero .sldtxt {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
@media only screen and (max-width:  699px ) {
  .heroBanner .slickItem-hero .sldtxt {
    position: static;
    padding: 15px;
  }
}
.heroBanner .slickItem-hero .sldtxt .container {
  height: 100%;
}
.heroBanner .slickItem-hero .sldtxt .container .row {
  height: 100%;
  display: flex;
  align-items: center;
}
.heroBanner .slickItem-hero .sldtxt .sldTxtInner {
  text-align: center;
  color: #fff;
  text-shadow: 0 1px 4px rgba(0, 0, 0, 0.5);
  padding-left: 100px;
  padding-right: 100px;
}
@media only screen and (max-width:  699px ) {
  .heroBanner .slickItem-hero .sldtxt .sldTxtInner {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.heroBanner .slickItem-hero .sldtxt .sldTxtInner h2.title {
  color: #fff;
  font-size: 4.5em;
  line-height: 1.1em;
  margin: 0;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .heroBanner .slickItem-hero .sldtxt .sldTxtInner h2.title {
    font-size: 3.375em;
    line-height: 1.1em;
  }
}
@media only screen and (max-width:  699px ) {
  .heroBanner .slickItem-hero .sldtxt .sldTxtInner h2.title {
    font-size: 1.875em;
    line-height: 1.1em;
  }
}
.heroBanner .slickItem-hero .sldtxt .sldTxtInner .preview {
  font-size: 1.25em;
  line-height: 1.2em;
  padding-top: 10px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .heroBanner .slickItem-hero .sldtxt .sldTxtInner .preview {
    font-size: 1.125em;
    line-height: 1.1em;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .heroBanner .slickItem-hero .sldtxt .sldTxtInner .preview {
    font-size: 1em;
    line-height: 1.1em;
  }
}
.heroBanner .slickItem-hero a.slideLink {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 15;
  display: block;
}
.heroBanner .slickItem-hero a.slideLink:focus .sldtxt .sldTxtInner {
  text-decoration: underline;
}
/* LEFT COLUMN
---------------------------------------------*/
.structLeft {
  padding-right: 30px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .structLeft {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media only screen and (max-width:  699px ) {
  .structLeft {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.structLeft .callout {
  border: 1px solid #8A8D8F;
  padding: 20px 15px;
  text-align: center;
  margin: 30px 0;
}
.structLeft .callout h2 {
  font-size: 1.25em;
  line-height: 1.25em;
}
.sectionTitle > h2 {
  margin: 0;
  padding: 0;
  list-style: none;
}
.sectionTitle > h2 span {
  display: none;
}
.sectionTitle > h2 span > a {
  display: block;
  background-color: #002857;
  color: #fff;
  font-size: 0.8em;
  line-height: 1.1em;
  text-transform: uppercase;
  font-weight: bold;
  padding: 10px 15px;
  text-decoration: none;
}
.sectionTitle > h2 span.selected {
  display: block;
}
.secondaryNav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.secondaryNav ul li a {
  display: block;
  line-height: normal;
  text-decoration: none;
}
.secondaryNav button.accordion-btn-wrap {
  background: none;
  border: none;
}
.secondaryNav button.accordion-btn-wrap:focus {
  outline: 2px solid #353535;
}
.secondaryNav > ul {
  margin-bottom: 80px;
}
.secondaryNav > ul > li {
  border-top: 1px solid #D9DAD8;
  transition: all ease 250ms;
}
.secondaryNav > ul > li:first-child {
  border: none;
}
.secondaryNav > ul > li > a {
  padding: 15px;
  color: #353535;
  text-transform: uppercase;
}
.secondaryNav > ul > li > a:hover,
.secondaryNav > ul > li > a:focus {
  background-color: #F2F2F2;
}
.secondaryNav > ul > li > a:focus {
  outline: 2px solid #4D4F53;
}
.secondaryNav > ul > li:hover {
  background-color: #F2F2F2;
}
.secondaryNav > ul > li.selected {
  background-color: #D9DAD8;
}
.secondaryNav > ul > li.selected > a:hover {
  background: none;
}
.secondaryNav > ul > li > ul {
  padding: 0 0 10px;
  font-size: 0.9375em;
  line-height: normal;
}
.secondaryNav > ul > li > ul > li > a {
  display: block;
  padding: 8px 15px 8px 25px;
  color: #353535;
}
.secondaryNav > ul > li > ul > li > a:focus {
  outline: 2px solid #353535;
}
.secondaryNav > ul > li > ul > li > a:hover {
  text-decoration: underline;
}
.secondaryNav > ul > li > ul > li.selected > a {
  font-weight: bold;
  font-style: italic;
}
.secondaryNav > ul > li > ul > li > ul {
  padding: 0 0 10px;
  font-size: 0.9375em;
  line-height: normal;
}
.secondaryNav > ul > li > ul > li > ul > li > a {
  display: block;
  padding: 8px 15px 8px 45px;
  color: #a5034c;
  font-weight: bold;
}
.secondaryNav > ul > li > ul > li > ul > li > a:focus {
  outline: 2px solid #353535;
}
.secondaryNav > ul > li > ul > li > ul > li > a:hover {
  text-decoration: underline;
}
.secondaryNav > ul > li > ul > li > ul > li.selected > a {
  font-weight: bold;
  font-style: italic;
}
/* FOOTER ELEMENTS
---------------------------------------------*/
footer {
  float: left;
  width: 100%;
  color: #353535;
  padding: 50px 0;
  font-size: 0.875em;
  line-height: 1.4em;
}
@media only screen and (max-width:  699px ) {
  footer {
    padding: 40px 0;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  footer .ftMain {
    padding-left: 20px;
    padding-right: 20px;
    margin-bottom: 45px;
  }
  footer .ftMain > div {
    display: flex;
    align-items: center;
  }
}
footer .footerLogo {
  width: 250px;
  margin-bottom: 30px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  footer .footerLogo {
    width: 335px;
    margin-bottom: 0;
  }
}
@media only screen and (max-width:  699px ) {
  footer .footerLogo {
    margin: 0 auto;
    display: block;
    width: 290px;
  }
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  footer .address {
    padding-left: 60px;
  }
}
footer address {
  font-style: normal;
}
footer .ftContact {
  padding-left: 30px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  footer .ftContact {
    padding-left: 20px;
  }
}
@media only screen and (max-width:  699px ) {
  footer .ftContact {
    padding-left: 15px;
  }
}
footer .ftLinks {
  padding-left: 30px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  footer .ftLinks {
    padding-right: 20px;
  }
}
@media only screen and (max-width:  699px ) {
  footer .ftLinks {
    padding-left: 15px;
  }
}
footer .ftLinks .footerLinks {
  overflow: hidden;
}
footer .ftLinks ul.split-list {
  margin: 0 -15px;
  padding: 0;
  list-style: none;
}
footer .ftLinks ul.split-list li a {
  padding: 5px 0;
  display: block;
}
footer h2 {
  color: #a5034c;
  border-bottom: 1px solid #8A8D8F;
  text-transform: uppercase;
  font-size: 1.15em;
  line-height: 1em;
  padding-bottom: 10px;
  margin-bottom: 15px;
}
@media only screen and (max-width:  699px ) {
  footer h2 {
    margin-top: 30px!important;
  }
}
footer a {
  color: #353535;
  text-decoration: none;
}
footer a:hover,
footer a:focus {
  text-decoration: underline;
  color: #353535;
}
footer a:focus {
  background: #fff;
}
.socialWrap {
  position: fixed;
  right: 0;
  top: 170px;
  background-color: #4D4F53;
  z-index: 200;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
}
@media only screen and (max-width:  699px ) {
  .socialWrap {
    position: static;
    background: none;
  }
}
.socialWrap ul.socialBar {
  margin: 0;
  padding: 5px 0;
  list-style: none;
  text-align: right;
  font-size: 22px;
}
@media only screen and (max-width:  699px ) {
  .socialWrap ul.socialBar {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
.socialWrap ul.socialBar li {
  display: block;
  padding: 0px 5px;
}
.socialWrap ul.socialBar li a {
  color: #fff;
  text-decoration: none;
  width: 40px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  display: block;
}
.socialWrap ul.socialBar li a:hover,
.socialWrap ul.socialBar li a:focus {
  background-color: #353535;
}
@media only screen and (max-width:  699px ) {
  .socialWrap ul.socialBar li a {
    color: #353535;
    width: 40px;
    height: 40px;
    line-height: 40px;
  }
  .socialWrap ul.socialBar li a:hover,
  .socialWrap ul.socialBar li a:focus {
    background-color: #fff;
  }
}
.toplink {
  display: block;
  text-align: center;
  font-size: 0.85em;
  text-transform: uppercase;
  line-height: normal;
  clear: both;
  background-color: #000;
}
.toplink a {
  text-decoration: none;
  padding: 10px;
  color: #fff;
  display: block;
}
/* MOBILE NAVIGATION
-----------------------------------------------*/
.mobileSearchWrap {
  padding: 0px;
}
.mobileSearchWrap .mobileSearch {
  margin: 0 0 0px;
  text-align: center;
  color: #333;
  position: relative;
  background: #eeeeee;
  border: 1px solid #ccc;
  height: 40px;
  overflow: hidden;
}
.mobileSearchWrap .mobileSearch input {
  background: #fff;
  border: none;
  padding: 0px 20px 0px 10px;
  width: 100%;
  border-radius: 0px;
  color: #333;
  height: 40px;
}
.mobileSearchWrap .mobileSearch img[id$="_imgbtnSearch"] {
  width: 25px;
  position: absolute;
  right: 10px;
  top: 0px;
  padding: 8px 0;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .mobileSearchWrap .mobileSearch img[id$="_imgbtnSearch"] {
    right: 90px;
  }
}
@media only screen and (max-width:  699px ) {
  .mobileSearchWrap .mobileSearch img[id$="_imgbtnSearch"] {
    right: 15px;
  }
}
.mobileSearchWrap .mobileSearch button,
.mobileSearchWrap .mobileSearch label,
.mobileSearchWrap .mobileSearch .SearchModuleSpacer {
  display: none;
}
.c-offcanvas {
  background: #C9CAC8;
  overflow-y: auto;
  z-index: 1000;
}
.c-offcanvas a {
  text-decoration: none;
}
.c-offcanvas a:focus {
  outline: 2px solid #D1D2D0;
}
.c-offcanvas button.accordion-btn-wrap {
  background: none;
  border: none;
}
.c-offcanvas button.accordion-btn-wrap:focus {
  outline: 2px solid #353535;
}
.c-offcanvas .menuTitleBar {
  background-color: #002857;
  color: #fff;
  display: flex;
  align-items: center;
  padding: 0px;
  justify-content: space-between;
}
.c-offcanvas .menuTitleBar h2 {
  color: inherit;
  font-weight: 300;
  margin: 0;
  font-size: 1.375em;
  line-height: 1em;
  padding: 15px;
}
.c-offcanvas .menuTitleBar .js-offcanvas-close {
  border: none;
  background: none;
  font-size: 22px;
  display: block;
  padding: 15px;
  width: 60px;
  height: 60px;
}
.c-offcanvas .menuTitleBar .js-offcanvas-close:focus {
  background-color: rgba(255, 255, 255, 0.2);
  outline: 2px solid #fff;
}
.c-offcanvas .c-offcanvas__inner {
  position: relative;
  height: 100%;
}
.c-offcanvas ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-offcanvas ul li a {
  display: block;
  transition: none;
}
.c-offcanvas .mainNav > ul {
  border-bottom: 1px solid #B3B3B3;
}
.c-offcanvas .mainNav > ul > li {
  border-top: 1px solid #B3B3B3;
}
.c-offcanvas .mainNav > ul > li > a {
  font-weight: bold;
  font-size: 1em;
  line-height: 1.15em;
  padding: 20px 15px;
  text-transform: uppercase;
  color: #353535;
}
.c-offcanvas .mainNav > ul > li > a:hover,
.c-offcanvas .mainNav > ul > li > a:focus {
  text-decoration: underline;
}
.c-offcanvas .mainNav > ul > li > a:focus {
  outline: 2px solid #4D4F53;
}
.c-offcanvas .mainNav > ul > li .accordion-btn-wrap {
  color: #353535;
  font-size: 20px;
  height: 45px;
  line-height: 45px;
}
.c-offcanvas .mainNav > ul > li .accordion-btn-wrap:focus {
  outline: 2px solid #4D4F53;
}
.c-offcanvas .mainNav > ul > li.selected,
.c-offcanvas .mainNav > ul > li.active {
  background-color: #353535;
  color: #fff;
  border-color: #4D4F53;
}
.c-offcanvas .mainNav > ul > li.selected > a,
.c-offcanvas .mainNav > ul > li.active > a {
  color: #fff;
}
.c-offcanvas .mainNav > ul > li.selected > .accordion-btn-wrap,
.c-offcanvas .mainNav > ul > li.active > .accordion-btn-wrap {
  color: #fff;
}
.c-offcanvas .mainNav > ul > li > ul {
  background: #353535;
}
.c-offcanvas .mainNav > ul > li > ul > li {
  background-color: transparent;
  border-top: 1px solid #4D4D4D;
}
.c-offcanvas .mainNav > ul > li > ul > li > a {
  color: #fff;
  font-weight: normal;
  padding: 15px 15px 15px 30px;
  font-size: 0.875em;
  line-height: normal;
}
.c-offcanvas .mainNav > ul > li > ul > li > .accordion-btn-wrap {
  color: #fff;
}
.c-offcanvas .mainNav > ul > li > ul > li > .accordion-btn-wrap:focus {
  outline: 2px solid #D1D2D0;
}
.c-offcanvas .mainNav > ul > li > ul > li.selected,
.c-offcanvas .mainNav > ul > li > ul > li.active {
  background-color: #4D4F53;
}
.c-offcanvas .mainNav > ul > li > ul > li.selected > a,
.c-offcanvas .mainNav > ul > li > ul > li.active > a {
  font-weight: bold;
}
.c-offcanvas .mainNav > ul > li > ul > li.selected > .accordion-btn-wrap,
.c-offcanvas .mainNav > ul > li > ul > li.active > .accordion-btn-wrap {
  color: #fff;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul {
  padding-bottom: 5px;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > a {
  color: #fff;
  font-weight: normal;
  padding: 12px 15px 12px 50px;
  font-size: 0.875em;
  line-height: normal;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > .accordion-btn-wrap {
  color: #ccc;
  height: 40px;
  font-size: 1em;
  overflow: hidden;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li.selected,
.c-offcanvas .mainNav > ul > li > ul > li > ul > li.active {
  font-weight: bold;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li.selected > a:hover,
.c-offcanvas .mainNav > ul > li > ul > li > ul > li.active > a:hover {
  text-decoration: underline;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul {
  background-color: #fff;
  padding: 5px 0 10px;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li > a {
  color: #000;
  font-weight: bold;
  padding: 7px 15px 7px 60px;
  font-size: 0.85em;
  line-height: normal;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li > a:hover {
  color: #353535;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li > .accordion-btn-wrap {
  color: #333;
  height: 40px;
  font-size: 1em;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li.selected,
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li.active {
  color: #353535;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li.selected > a,
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li.active > a {
  color: #353535;
}
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li.selected > .accordion-btn-wrap,
.c-offcanvas .mainNav > ul > li > ul > li > ul > li > ul > li.active > .accordion-btn-wrap {
  color: #353535;
}
.c-offcanvas .mobileUtils {
  font-size: 0.875em;
  line-height: normal;
  text-transform: uppercase;
}
.c-offcanvas .mobileUtils ul {
  margin: 0;
  padding: 0;
  list-style: none;
}
.c-offcanvas .mobileUtils ul li {
  margin-bottom: 1px;
}
.c-offcanvas .mobileUtils ul li a {
  background: #002857;
  color: #fff;
  display: block;
  padding: 15px;
}
/* HOMEPAGE ELEMENTS
---------------------------------------------*/
.home .structBody {
  padding: 0;
}
.sectionWrap {
  padding-top: 70px;
  padding-bottom: 50px;
  position: relative;
  overflow: hidden;
}
.sectionWrap.noContent {
  display: none;
}
@media only screen and (max-width:  699px ) {
  .sectionWrap {
    padding-top: 45px;
  }
}
.sectionWrap .sectionBkgd {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.1;
  filter: grayscale(1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}
h2.sectionHeader {
  text-align: center;
  display: block;
  border-bottom: 1px solid #353535;
  font-size: 2.25em;
  padding-bottom: 10px;
  margin: 0 0 45px;
  letter-spacing: 1.5px;
}
@media only screen and (max-width:  699px ) {
  h2.sectionHeader {
    font-size: 1.875em;
    line-height: 1.1em;
    margin-bottom: 30px;
    letter-spacing: 1px;
  }
}
.sectionBtn {
  text-align: center;
}
.whiteBkgd h2.sectionHeader {
  color: #a5034c;
}
.whiteBkgd .button {
  background: #4D4F53;
  color: #fff;
  margin: 0 10px;
}
.whiteBkgd .button:hover,
.whiteBkgd .button:focus {
  background: #353535;
}
.whiteBkgd .button:focus {
  outline: 2px solid #D1D2D0;
}
.whiteBkgd.optRow h2.sectionHeader {
  color: #353535;
}
.ltGrayBkgd {
  background-color: #F7F7F7;
}
.ltGrayBkgd h2.sectionHeader {
  color: #353535;
}
.ltGrayBkgd .button {
  background: #274971;
  color: #fff;
  margin: 0 10px;
}
.ltGrayBkgd .button:hover,
.ltGrayBkgd .button:focus {
  background: #002857;
}
.ltGrayBkgd .button:focus {
  outline: 2px solid #D1D2D0;
}
.dkBlueBkgd {
  background-color: #002857;
  color: #fff;
}
.dkBlueBkgd a {
  color: #fff;
}
.dkBlueBkgd h2.sectionHeader {
  color: #fff;
  border-color: #fff;
}
.dkBlueBkgd .button {
  background: #fff;
  color: #002857;
  margin: 0 10px;
  border: 1px solid transparent;
}
.dkBlueBkgd .button:hover,
.dkBlueBkgd .button:focus {
  background: #002857;
  border-color: #fff;
  color: #fff;
}
.slickEventsWrap {
  margin: 50px -15px 75px;
}
@media only screen and (max-width:  699px ) {
  .slickEventsWrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.slickEventsWrap.slickWrap .slick-arrow {
  top: 75px;
}
.slickEventsWrap.slickWrap .slick-arrow.slick-prev {
  left: 0px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickEventsWrap.slickWrap .slick-arrow.slick-prev {
    left: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  .slickEventsWrap.slickWrap .slick-arrow.slick-prev {
    left: 10px;
  }
}
.slickEventsWrap.slickWrap .slick-arrow.slick-next {
  right: 0px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickEventsWrap.slickWrap .slick-arrow.slick-next {
    right: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  .slickEventsWrap.slickWrap .slick-arrow.slick-next {
    right: 10px;
  }
}
.slickEventsWrap .slick-eventItem .date {
  width: 150px;
  height: 150px;
  border: 1px solid #353535;
  text-align: center;
  color: #a5034c;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  margin: 0 auto 30px;
  transition: all ease 250ms;
  background: #fff;
}
.slickEventsWrap .slick-eventItem .date .month {
  text-transform: uppercase;
  line-height: 1em;
}
.slickEventsWrap .slick-eventItem .date .day {
  font-size: 4.625em;
  line-height: 1em;
  font-weight: bold;
  letter-spacing: -2px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickEventsWrap .slick-eventItem .date .day {
    font-size: 4em;
  }
}
.slickEventsWrap .slick-eventItem:hover .date {
  background-color: #a5034c;
  color: #fff;
  border-color: #a5034c;
}
.slickEventsWrap .slick-eventItem .text {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.slickEventsWrap .slick-eventItem .text .tools {
  font-size: 22px;
  margin-top: 10px;
}
.slickEventsWrap .slick-eventItem .text .tools a {
  color: #353535;
}
.slickEventsWrap .slick-eventItem .text .tools a:focus,
.slickEventsWrap .slick-eventItem .text .tools a:hover {
  color: #D1D2D0;
}
.slickEventsWrap .slick-eventItem .text .tools a:focus {
  outline: 2px solid #D1D2D0;
}
.slickEventsWrap .slick-eventItem .text h3.title {
  margin: 0;
}
.slickEventsWrap .slick-eventItem .text h3.title a:hover {
  text-decoration: underline;
}
.slickEventsWrap .slick-eventItem .text .preview {
  padding-top: 10px;
}
.slickNewsWrap {
  margin: 45px -15px 50px;
}
@media only screen and (max-width:  699px ) {
  .slickNewsWrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.slickNewsWrap.slickWrap .slick-arrow {
  top: 170px;
}
@media only screen and (max-width:  699px ) {
  .slickNewsWrap.slickWrap .slick-arrow {
    top: 130px;
  }
}
.slickNewsWrap.slickWrap .slick-arrow.slick-prev {
  left: 0px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickNewsWrap.slickWrap .slick-arrow.slick-prev {
    left: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  .slickNewsWrap.slickWrap .slick-arrow.slick-prev {
    left: 10px;
  }
}
.slickNewsWrap.slickWrap .slick-arrow.slick-next {
  right: 0px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickNewsWrap.slickWrap .slick-arrow.slick-next {
    right: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  .slickNewsWrap.slickWrap .slick-arrow.slick-next {
    right: 10px;
  }
}
.slickNewsWrap.slickWrap a {
  display: block;
}
.slickNewsWrap.slickWrap .text {
  padding-left: 5px;
  padding-right: 5px;
}
.slickNewsWrap.slickWrap .text h3.title {
  font-size: 1.5em;
  text-transform: none;
  color: #002857;
  margin: 0;
}
.slickNewsWrap.slickWrap .text .preview {
  padding-top: 10px;
}
.slickExclWrap {
  margin: 45px -15px 50px;
}
@media only screen and (max-width:  699px ) {
  .slickExclWrap {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}
.slickExclWrap.slickWrap .slick-arrow {
  top: 130px;
  color: #353535;
}
.slickExclWrap.slickWrap .slick-arrow.slick-prev {
  left: 0px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickExclWrap.slickWrap .slick-arrow.slick-prev {
    left: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  .slickExclWrap.slickWrap .slick-arrow.slick-prev {
    left: 10px;
  }
}
.slickExclWrap.slickWrap .slick-arrow.slick-next {
  right: 0px;
}
@media only screen and (min-width: 700px) and (max-width:  1023px ) {
  .slickExclWrap.slickWrap .slick-arrow.slick-next {
    right: 5px;
  }
}
@media only screen and (max-width:  699px ) {
  .slickExclWrap.slickWrap .slick-arrow.slick-next {
    right: 10px;
  }
}
.slickExclWrap.slickWrap a {
  color: #fff;
  display: block;
}
.slickExclWrap.slickWrap .text {
  padding-left: 5px;
  padding-right: 5px;
  color: #fff;
  text-align: center;
}
.slickExclWrap.slickWrap .text h3.title {
  color: #fff;
  margin: 0;
}
.slickExclWrap.slickWrap .text .preview {
  padding-top: 10px;
  color: #fff;
}
